import { Box, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { COMMON_SERVICES } from "../../../../Services/Common/CommonService";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { COLORS } from "../../../../SupportingFiles/colors";
import {
  isEmpty,
  validateFullName,
  validateMobileNumber,
} from "../../../../SupportingFiles/HelpingFunction";
import { TFieldData } from "../../../../types/Common/TFieldData";
import JDSButtons from "../../../InputFields/JDSButtons/JDSButtons";
import TextInputField from "../../../InputFields/TextInputField/TextInputField";
import "./../../../../SCSS/ModalPopup.scss";

const ExitIntentPopup = ({
  open,
  setOpen,
  type,
}: {
  open: boolean;
  setOpen: Function;
  type: any;
}) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [thank, setThank] = useState(false);
  const [load, setLoad] = useState(false);
  const [mobile, setMobile] = useState("");
  const [shareURLData, setShareURLData] = useState<{
    mobile: TFieldData;
    name: TFieldData;
  }>({
    mobile: { value: "", warning: false },
    name: { value: "", warning: false },
  });

  const handleDetails = (attrName: any, value: any) => {
    if (attrName === "mobile") {
      setShareURLData((prev) => ({
        ...prev,
        mobile: { value: value, warning: !validateMobileNumber(value) },
      }));
    }
    if (attrName === "name") {
      setShareURLData((prev) => ({
        ...prev,
        name: { value: value, warning: !validateFullName(value) },
      }));
    }
  };

  const EVENT_TRACKER = () => {
    setLoad(true);
    let Mdata = { ...shareURLData };
    const hasError =
      Mdata.name.warning ||
      Mdata.mobile.warning ||
      isEmpty(Mdata.mobile.value) ||
      isEmpty(Mdata.name.value);

    if (hasError) {
      toast.error("Please fill mandatory fields.");
      setLoad(false);
      return;
    }

    const onSuccess = () => {
      setLoad(false);
      setOpen(false);
      setThank(true);
      setShareURLData({
        mobile: { value: "", warning: false },
        name: { value: "", warning: false },
      });
      setIsFormSubmitted(true);
      localStorage.setItem("isFormSubmitted", JSON.stringify(true));
    };

    const onError = (err: any) => {
      console.log(err);
      setOpen(false);
      setLoad(false);
      setShareURLData({
        mobile: { value: "", warning: false },
        name: { value: "", warning: false },
      });
    };
    setMobile(Mdata?.mobile?.value);
    const data = {
      name: Mdata.name.value,
      mobile: Mdata.mobile.value,
      insurance_type:
        type === "Health"
          ? "Health Insurance"
          : type === "Car"
          ? "Car Insurance"
          : type === "TW"
          ? "Tw Insurance"
          : "Term Insurance",
    };

    COMMON_SERVICES.CLICK_TO_CALL(onSuccess, onError, data);
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        setIsFormSubmitted(false);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setThank(false);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [thank]);





  return (
    <>
    <Modal open={open && !isFormSubmitted}>
    <Box
      className="modalWrapper"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box className="modalContent smWidth">
        <Grid className="topbar" container alignItems="center">
          <Grid size={12} textAlign={"right"}>
            <Button
              disableRipple
              className="closePopup"
              onClick={() => {
                setShareURLData({
                  mobile: { value: "", warning: false },
                  name: { value: "", warning: false },
                });
                setOpen(false);
                setLoad(false);
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          className="row"
          columnSpacing={2}
          alignItems="flex-start"
        >
          <Grid size={12}>
            <Box>
              <h3
                style={{
                  fontWeight: "900",
                  textAlign: "left",
                  marginBottom: "16px",
                }}
              >
                Happy to help
              </h3>
              <p className="mb-4">
                Could not find what you are looking for? If you need
                assistance with your insurance needs, we’re here for you.
              </p>

              <Grid size={12}>
                <TextInputField
                  class_name="inputField mb-6"
                  title={"Enter your full name"}
                  value={shareURLData.name.value}
                  placeholder="Eg: John Smith"
                  attrName={"name"}
                  value_update={handleDetails}
                  error_message={
                    !isEmpty(shareURLData.name.value)
                      ? "Enter Full Name"
                      : "Enter Valid Full Name"
                  }
                  warn_status={shareURLData.name.warning}
                  validation_type="NAME"
                />
              </Grid>
              <Box className="mobileNumber">
                <Box
                  position="absolute"
                  left="12px"
                  bottom="13px"
                  zIndex="2"
                  fontSize="18px"
                  fontWeight="bold"
                  sx={{ color: COLORS.lightgrey }}
                >
                  +91
                </Box>
                <TextInputField
                  class_name="inputField"
                  title={"Enter your mobile number"}
                  value={shareURLData.mobile.value}
                  placeholder="XXXXXXXXXX"
                  attrName={"mobile"}
                  value_update={handleDetails}
                  error_message={
                    !isEmpty(shareURLData.mobile.value)
                      ? "Enter Valid Mobile Number"
                      : "Enter Mobile Number"
                  }
                  warn_status={shareURLData.mobile.warning}
                  validation_type="NUMBER"
                  max_length={10}
                />
              </Box>

              <Grid size={12} className="mt-8" textAlign={"center"}>
                <JDSButtons
                  text={"Submit"}
                  variant={"text"}
                  className={"primaryBtns large"}
                  fullWidth={false}
                  loader={load}
                  disabled={load}
                  onClick={() => {
                    EVENT_TRACKER();
                  }}
                />
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  </Modal>
  <Modal open={thank}>
    <Box
      className="modalWrapper"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box className="modalContent smWidth">
        <Grid className="topbar" container alignItems="center">
          <Grid size={12} textAlign={"right"}>
            <Button
              disableRipple
              className="closePopup"
              onClick={() => {
                setThank(false);
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          className="row"
          columnSpacing={2}
          alignItems="flex-start"
        >
          <Grid size={12}>
            <Box>
              <h3
                style={{
                  fontWeight: "900",
                  textAlign: "left",
                  marginBottom: "16px",
                }}
              >
                Thank you for your interest.
              </h3>
              <p className="mb-4">
                We will get in touch with you on {mobile}
              </p>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  </Modal>
  </>
  );
};

export default ExitIntentPopup;
