import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { subYears } from "date-fns";
import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { blog_data } from "../../../Component/Desktop/Common/BlogArray/BLogArray";
import BlogCard from "../../../Component/Desktop/Common/BlogCard/BlogCard";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import TestimonialCard from "../../../Component/Desktop/Common/TestimonialCard/TestimonialCard";
import FAQ from "../../../Component/Global/FAQ/FAQ";
import GenderRadio from "../../../Component/Global/GenderRadio/GenderRadio";
import CustomCheckbox from "../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import DatePicker from "../../../Component/InputFields/DatePicker/DatePicker";
import JDSButtons from "../../../Component/InputFields/JDSButtons/JDSButtons";
import TextInputField from "../../../Component/InputFields/TextInputField/TextInputField";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import TermFormContainer from "../../../Container/Term/Form/TermFormContainer";
import { COMMON_ROUTES } from "../../../Router/Path/CommonRoutes";
import styles from "../../../SCSS/CMSPages.module.scss";
import { SESSION_CONSTANTS } from "../../../Services/Constants/SessionConstants";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { CarSlice } from "../../../Store/Slice_Reducer/Car/CarSlice";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { TermSlice } from "../../../Store/Slice_Reducer/Term/TermSlice";
import { TWSlice } from "../../../Store/Slice_Reducer/TwoWheeler/TWSlice";
import { COLORS } from "../../../SupportingFiles/colors";
import HelmetComponent from "../../../SupportingFiles/HelmetComponent";
import {
  detectWebViewEnvironment,
  isEmpty,
  validateMobileNumber,
} from "../../../SupportingFiles/HelpingFunction";
import { verifyJwt } from "../../../SupportingFiles/JWTDecoder";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import {
  changeHeaderFooterVisibility,
  isIOS,
} from "../../../SupportingFiles/SendEventToApp";
import { getJFSJwtToken } from "../../../SupportingFiles/SendwtAuthToken";
import { TTermAddForm } from "../../../types/Term/TTermAddForm";
import { FRONTEND_DOMAIN } from "../../../URLCollection/Domain";
import { pushDataLayer } from "../../../utils/analytics";

function TermProductPage() {
  type BlogCard = {
    id: string;
    img_url: string;
    blogtitle: string;
    blog_desc: string;
  };
  const [blogCards, setBlogCards] = useState<BlogCard[]>([]);
  useEffect(() => {
    setBlogCards(blog_data);
  }, []);

  const blogCardsArray = blogCards.slice(0, 3);
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const defaultShow = 5;
  const [showAll, setShowAll] = useState(false);
  const { Term } = useAppSelector((state) => state);
  const [userMobileNumber, setUserMobileNumber] = useState("");

  const whySectionArray = [
    {
      iconUrl: "../images/right-cover.svg",
      heading: "Right Cover",
      desc: "We recommend right cover amount as per your financial needs.",
    },
    {
      iconUrl: "../images/choose-best.svg",
      heading: "Help to choose the best",
      desc: "We help you choose plans from the best general and health insurance companies.",
    },
    {
      iconUrl: "../images/tax-benefit.svg",
      heading: "Tax benefits",
      desc: "Get tax benefit under Section 80D.",
    },
  ];
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 320 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const testimonialsSlider = [
    {
      review:
        "“Extremely happy. Very satisfied with your express settlement of claims. My experience with you is unique as compared with others. I wish your company a very bright and prosperous future. Thank You!”",
      reviewer_name: "Amit Khandewal",
      location: "Delhi",
    },
    {
      review:
        " “Buying car insurance has never been so easy. It just took me 5 minutes to buy the policy. Very easy and quick. Will recommend it to everyone.”",
      reviewer_name: "Dr. Rupa Jha",
      location: "Mumbai",
    },
    {
      review: `“The multiple options from various Insurers under one roof was helpful and gave me the confidence that I am opting for the right product as required. Excellent support provided by your advisor.”`,
      reviewer_name: "Kunal Sharma",
      location: "Banglore",
    },
    {
      review: `“Renewing my Car insurance from Jio Insurance Broking was a great experience. Overall experience was really good and I was able to complete my transaction in just 5 minutes. I recommend everyone to buy from Jio Insurance Broking Limited.”`,
      reviewer_name: "Jamnadas Bhalani",
      location: "Delhi",
    },
    {
      review: `“Buying ICICI Lombard car insurance from Jio Insurance Broking is great. It just took me 5 minutes to buy a policy online. Very easy and user friendly. Will recommend it to everyone.”`,
      reviewer_name: "Vikas Priyadarshi",
      location: "Indore",
    },
    {
      review: `“The team handled my renewal query and process very smoothly. They also convinced me to go for a personal cover policy which I am happy to opt for. They are a good asset to the team.”`,
      reviewer_name: "Kunal Chavda",
      location: "Chandigarh",
    },
    {
      review: `“The online quote system was simple to use with clear options. The additional options are well laid out and explained on the website, so I knew exactly what I could buy and compare to what I want. Hence renewed my two-wheeler policy online without any trouble. Excellent website”`,
      reviewer_name: "Chirag Lukka",
      location: "Delhi",
    },
    {
      review: `“I renewed my health and motor policy on icicilombard.com and it was a seamless experience. On my previous policy, I had registered a claim last month and I was pleasantly surprised with the claim settlement process. It was extremely hassle free and quick.”`,
      reviewer_name: "Abhishek Gupta",
      location: "Mumbai",
    },
    {
      review: `“Extremely happy. Very satisfied with your express settlement of claims. My experience with you is unique as compared with others. I wish your company a very bright and prosperous future. THANKS ICICI LOMBARD”`,
      reviewer_name: "Leena Vaddella",
      location: "Delhi",
    },
  ];
  const partersLogos = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 320 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const partnersSlider = [
    {
      imgUrl:
        "./images/insurerlogo/Tata-AIA-Life-Insurance-Company-Limited.svg",
    },
    { imgUrl: "./images/insurerlogo/HDFC-Standard-Life-Insurance-Co-Ltd.svg" },
    {
      imgUrl: "./images/insurerlogo/ICICI-Prudential-Life-Insurance-Co-Ltd.svg",
    },
    {
      imgUrl: "./images/insurerlogo/Max-Life-Insurance-Co-Ltd.svg",
    },
  ];
  const typesLifeinsuranceArray = [
    {
      heading: "Term plan",
      desc: "A term plan is a specific type of term insurance policy that provides protection for a definite period of time or 'term'. In the event of an unfortunate demise of the insured person during the specified term, the insurance company pays the beneficiaries of the insured a predetermined sum of money.",
    },
    {
      heading: "Endowment plan",
      desc: "An endowment plan is a term insurance plan that offers a term cover and helps you grow your money. It provides returns that are fixed at the time of the purchase of the policy. It can be used to save for various goals like buying a house, your child’s education or marriage, starting a new venture and more.",
    },
    {
      heading: "Money back plan",
      desc: "Money back plans mean that money is returned to the term insured as a survival benefit after a set period. When the policyholder survives the policy term, the money back is guaranteed. In the event of the policyholder's death, the nominee receives the amount guaranteed as well as accumulated bonuses, if any.",
    },
    {
      heading: "Whole term plan",
      desc: "Whole term insurance, as its name goes, refers to an insurance plan, which covers you for a lifetime. The maturity age for a whole life plan is about 100 years and it comes with a maturity benefit. This is to imply that either whole term insurance will give you the sum assured at the time of death (death benefit) or it will give you the promised maturity benefit if you live up to 100 years.",
    },
    {
      heading: "Unit Linked Plan (ULIP)",
      desc: "Unit Linked Insurance Plan is a multi-faceted term insurance product. A ULIP plan is a combination of term insurance and investment. In this insurance plan customer gets to choose his investment funds with an option to invest in equity markets along with flexibility of choosing higher life coverage.",
    },
  ];
  const faqs = [
    {
      title: "Is there an age limit to buy term insurance policy?",
      desc: "Any person who is 18 years or more can purchase term insurance policy, which has to be as per the conditions of the Indian Contract Act 1872.",
    },
    {
      title: "What is premium-paying term?",
      desc: "Premium paying term is the number of years for the insured/policyholder to pay the premium.",
    },
    {
      title: "Why should I buy term insurance?",
      desc: "Term Insurance policies act as a safety net for the family. You can get a huge amount of life cover (i.e. the sum assured) at a comparatively low premium.",
    },
    {
      title: "What are riders in term Insurance?",
      desc: "Riders are optional, extra terms that go into effect along with your basic policy, at an additional cost. Simply put, a rider provides additional coverage and added protection against risks.",
    },
    {
      title: "How much term Insurance coverage is recommended?",
      desc: "The term insurance coverage amount should be enough to support your family financially after you. It is recommended to have life cover of at least ten times of your annual income.",
    },
    {
      title: "Is term insurance mandatory?",
      desc: "No, term Insurance is not mandatory but when you have dependents like children, parents & spouse, then term insurance becomes an integral aspect of your life. It provides you and your family financial protection even after your death.",
    },
    {
      title: "What types of riders are available in term Insurance?",
      desc: (
        <>
          Accidental death benefit rider
          <br /> Critical illness rider
          <br /> Permanent disability rider
          <br /> Income benefit rider <br />
          Waiver of premium rider
        </>
      ),
    },
  ];

  const [redirected, setRedirected] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    changeHeaderFooterVisibility(0, (response) => {
      console.log("Native app response:", response);
    });

    getJFSJwtToken()
      .then((res) => {
        var modifiedStr = `${JSON.stringify(res)}`;
        let token = modifiedStr.slice(1, -1);
        if (!isIOS()) {
          const response = JSON.parse(res);
          token = response.data.jwtToken;
        } else {
          // try {
          //   toast.success("responseIostry" + `${token}`);
          // } catch (error) {
          //   toast.error("Error processing iOS token: " + error);
          // }
        }
        // const response = JSON.parse(res);
        // const token = response?.data?.jwtToken;
        verifyJwt(token).then((result) => {
          if (!isEmpty(result?.payload?.data)) {
            const mobile_no = result?.payload?.data;
            setUserMobileNumber(result?.payload?.data.slice(2));

            dispatch(CarSlice.actions.BULK_UPDATE(CarSlice.getInitialState()));
            dispatch(
              HealthSlice.actions.BULK_UPLOAD(HealthSlice.getInitialState())
            );
            dispatch(
              TWSlice.actions.BULK_UPDATE({
                ...TWSlice.getInitialState(),
              })
            );
            // setTimeout(() => {
            dispatch(
              TermSlice.actions.BULK_UPDATE({
                ...TermSlice.getInitialState(),
                REDIRECTED: true,
                ADD_FORM: {
                  ...TermSlice.getInitialState().ADD_FORM,
                  mobile: { value: `${mobile_no.slice(2)}`, warning: false },
                },
              })
            );
            // }, 1000);
            setRedirected(true);
          } else {
            setRedirected(false);
            dispatch(
              TermSlice.actions.BULK_UPDATE({
                ...TermSlice.getInitialState(),
              })
            );
          }
        });
      })
      .catch((error) => {
        // toast.error(`Failed to get JWT token: ${error.message}`);
      });
  }, []);

  const [openTermForm, setOpenTermForm] = useState(false);

  const validateTerm = () => {
    let data: TTermAddForm = { ...Term.ADD_FORM };
    data = {
      ...data,
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
      // email: {
      //   ...data.email,
      //   warning: !validateEmail(data.email.value),
      // },
      age: {
        ...data.age,
        warning: isEmpty(data.age.value),
      },
    };

    dispatch(TermSlice.actions.UPDATE_ADD_FORM_DATA(data));
    if (!data.mobile.warning && !data.age.warning) {
      pushDataLayer(
        "jioinsure_home_cards",
        "life insurance",
        "individual CTA",
        "Insure website"
      );
      setOpenTermForm(true);
    }
  };

  useEffect(() => {
    if (userMobileNumber && detectWebViewEnvironment()) {
      dispatch(
        TermSlice.actions.UPDATE_ADD_FORM_DATA({
          ...Term.ADD_FORM,
          mobile: {
            value: userMobileNumber,
            warning: false,
          },
        })
      );
    }
  }, [userMobileNumber]);

  useEffect(() => {
    // Exit early if not in WebView
    if (!detectWebViewEnvironment()) return;

    const termFormData = sessionStorage.getItem(
      SESSION_CONSTANTS.TERM_FORM_DATA
    );
    const openTermForm = sessionStorage.getItem(
      SESSION_CONSTANTS.OPEN_TERM_FORM
    );
    if (termFormData) {
      try {
        const parsedTermFormData = JSON.parse(termFormData);
        // Set a timeout to delay the dispatch
        const timer = setTimeout(() => {
          // Dispatch the parsed term form data to Redux
          dispatch(
            TermSlice.actions.BULK_UPDATE({
              ...Term,
              REDIRECTED: true,
              ADD_FORM: {
                ...Term.ADD_FORM,
                ...parsedTermFormData,
              },
            })
          );

          // Handle openTermForm if it exists
          if (openTermForm) {
            const parsedOpenTermForm = JSON.parse(openTermForm);
            setOpenTermForm(parsedOpenTermForm);
            sessionStorage.removeItem(SESSION_CONSTANTS.OPEN_TERM_FORM); // Remove after use
          }

          // Clean up termFormData from sessionStorage
          // sessionStorage.removeItem("termFormData");
        }, 500); // Delay of 500ms

        // Cleanup function to clear the timeout if the component unmounts
        return () => {
          clearTimeout(timer); // Ensure the timer is cleared
        };
      } catch (error) {
        console.error(
          "Failed to parse carFormData from sessionStorage:",
          error
        );
      }
    }
  }, []);

  // Effect for saving data on component unmount
  useEffect(() => {
    if (!detectWebViewEnvironment()) return;

    return () => {
      sessionStorage.setItem(
        SESSION_CONSTANTS.TERM_FORM_DATA,
        JSON.stringify(Term.ADD_FORM)
      );
    };
  }, [Term.ADD_FORM]);

  const breadcrumbJsonLd = JSON.stringify(
    {
      "@context": "https://schema.org/",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Home",
          item: `${FRONTEND_DOMAIN}`,
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Term Insurance",
          item: `${FRONTEND_DOMAIN}/term-insurance`,
        },
      ],
    },
    null,
    2
  ); // Added 'null, 2' to format it with indentation

  const faqsJsonLd = JSON.stringify(
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "Is there an age limit to buy term insurance policy?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Any person who is 18 years or more can purchase term insurance policy, which has to be as per the conditions of the Indian Contract Act 1872.",
          },
        },
        {
          "@type": "Question",
          name: "What is premium-paying term?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Premium paying term is the number of years for the insured/policyholder to pay the premium.",
          },
        },
        {
          "@type": "Question",
          name: "Why should I buy term insurance?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Term Insurance policies act as a safety net for the family. You can get a huge amount of life cover (i.e. the sum assured) at a comparatively low premium.",
          },
        },
        {
          "@type": "Question",
          name: "What are riders in term Insurance?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Riders are optional, extra terms that go into effect along with your basic policy, at an additional cost. Simply put, a rider provides additional coverage and added protection against risks.",
          },
        },
        {
          "@type": "Question",
          name: "How much term Insurance coverage is recommended?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The term insurance coverage amount should be enough to support your family financially after you. It is recommended to have life cover of at least ten times of your annual income.",
          },
        },
        {
          "@type": "Question",
          name: "Is term insurance mandatory?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "No, term Insurance is not mandatory but when you have dependents like children, parents & spouse, then term insurance becomes an integral aspect of your life. It provides you and your family financial protection even after your death.",
          },
        },
        {
          "@type": "Question",
          name: "What types of riders are available in term Insurance?",
          acceptedAnswer: {
            "@type": "Answer",
            text: `Accidental death benefit rider
        Critical illness rider
        Permanent disability rider
        Income benefit rider\nWaiver of premium rider`,
          },
        },
      ],
    },
    null,
    2
  ); // Added 'null, 2' to format it with indentation

  const productsJsonLd = JSON.stringify(
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      name: "Term Insurance",
      image: `${FRONTEND_DOMAIN}/images/jfslogofinal.svg`,
      description:
        "Compare and buy Term insurance online. Comprehensive coverage across India. Book now and get the right quotes! Jio Insurance Broking Ltd.",
      brand: {
        "@type": "Brand",
        name: "Jio Insurance",
      },
      offers: {
        "@type": "AggregateOffer",
        url: `${FRONTEND_DOMAIN}/term-insurance`,
        priceCurrency: "INR",
        lowPrice: "",
      },
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: "4.5",
        ratingCount: "500",
      },
    },
    null,
    2
  ); // Added 'null, 2' to format it with indentation

  return (
    <>
      {openTermForm ? (
        <TermFormContainer open={openTermForm} setOpen={setOpenTermForm} />
      ) : null}

      <HelmetComponent
        title="Buy the best term insurance policy online, get up to 20%* Off"
        description="Compare & buy term insurance policy online to save up to 20%* on premiums. choose the best plans, affordable premiums, & secure your family's financial future today."
        showCanonicalTag={true}
        scripts={[breadcrumbJsonLd, faqsJsonLd, productsJsonLd]} // Pass multiple scripts here
      />
      <Box className={`${styles.cmsWrapper} ${styles.sectionSpacing}`}>
        {isMobile ? <MNavBar /> : <NavBar />}

        <Box className="heroBanner">
          <img
            alt=""
            src="../images/life-hero-banner.webp"
            width="100%"
            className="bgimg"
          />
          <Box className="innerWrapper">
            <Grid container spacing={3} alignItems="center">
              <Grid
                size={{ xs: 12, md: 6 }}
                display="flex"
                gap="16px"
                flexDirection="column"
                sx={{ color: COLORS.white }}
                className="contentBox"
              >
                <h1>Term Insurance</h1>
                <h2>Protect your present for a secured future</h2>
                <Box>
                  <p>
                    <img alt="" src="../images/green-tick.svg" width="32px" />
                    Hassle-free buying process
                  </p>
                  <p>
                    <img alt="" src="../images/green-tick.svg" width="32px" />
                    Affordable premium
                  </p>
                  <p>
                    <img alt="" src="../images/green-tick.svg" width="32px" />
                    Flexible policy term
                  </p>
                  <p
                    style={{
                      color: "#fff",
                      fontSize: "12px",
                    }}
                  >
                    *Subject to availability
                  </p>
                </Box>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Box className="formBox" gap="16px!important">
                  <Box display="flex" flexDirection="column" gap="16px">
                    <Grid container spacing={2} alignItems="flex-start">
                      <Grid size={12}>
                        <h3>Buy term insurance with up to 20%* discount </h3>
                      </Grid>
                      <Grid size={12}>
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            textAlign: "left",
                            marginBottom: "8px",
                          }}
                        >
                          Select your gender
                        </p>
                        <GenderRadio
                          value={Term.ADD_FORM.gender.value}
                          value_update={(a: string, v: string) => {
                            dispatch(
                              TermSlice.actions.UPDATE_ADD_FORM_DATA({
                                ...Term.ADD_FORM,
                                gender: { value: v, warning: false },
                              })
                            );
                          }}
                        />
                      </Grid>
                      <Grid size={12}>
                        <Box className="mobileNumber">
                          <Box
                            position="absolute"
                            left="12px"
                            bottom="13px"
                            zIndex="2"
                            fontSize="18px"
                            fontWeight="bold"
                            sx={{ color: COLORS.lightgrey }}
                          >
                            +91
                          </Box>
                          <TextInputField
                            disabled={redirected}
                            title="Enter mobile number"
                            placeholder="XXXXXXXXXX"
                            validation_type="NUMBER"
                            value={Term.ADD_FORM.mobile.value}
                            attrName={"mobile"}
                            value_update={(a: any, v: any) => {
                              dispatch(
                                TermSlice.actions.UPDATE_ADD_FORM_DATA({
                                  ...Term.ADD_FORM,
                                  mobile: {
                                    value: v,
                                    warning: !validateMobileNumber(v),
                                  },
                                })
                              );
                            }}
                            warn_status={Term.ADD_FORM.mobile.warning}
                            error_message={
                              isEmpty(Term.ADD_FORM.mobile.value)
                                ? "Enter mobile number"
                                : "Enter valid mobile number"
                            }
                            max_length={10}
                          />
                        </Box>
                      </Grid>

                      {/* <Grid size={12}>
                        <Box>
                          <TextInputField
                            title="Enter your email"
                            placeholder="Eg: john.smith@email.com"
                            value={Term.ADD_FORM.email?.value}
                            attrName={"email"}
                            value_update={(a: any, v: any) => {
                              dispatch(
                                TermSlice.actions.UPDATE_ADD_FORM_DATA({
                                  ...Term.ADD_FORM,
                                  email: {
                                    value: v,
                                    warning: !validateEmail(v),
                                  },
                                })
                              );
                            }}
                            warn_status={Term.ADD_FORM.email?.warning}
                            error_message={
                              isEmpty(Term.ADD_FORM.email?.value)
                                ? "Enter E-mail"
                                : "Enter valid E-mail"
                            }
                            max_length={50}
                          />
                        </Box>
                      </Grid> */}
                      <Grid size={12}>
                        <DatePicker
                          title={"Date of Birth"}
                          value={Term.ADD_FORM.age.value}
                          attrName={"age"}
                          onChange={(a: any, v: any) => {
                            dispatch(
                              TermSlice.actions.UPDATE_ADD_FORM_DATA({
                                ...Term.ADD_FORM,
                                age: { value: v, warning: false },
                              })
                            );
                          }}
                          warn_status={Term.ADD_FORM.age.warning}
                          error_message="Select DOB"
                          min_date={65}
                          max_date={18}
                          default_date={subYears(new Date(), 18)}
                        />
                      </Grid>
                      <Grid size={12}>
                        <CustomCheckbox
                          label={
                            <>
                              I agree to{" "}
                              <Link
                                style={{
                                  color: COLORS.primary,
                                  fontWeight: "bold",
                                }}
                                onClick={() => {
                                  navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                                }}
                              >
                                Terms & Conditions
                              </Link>
                              ,{" "}
                              <Link
                                style={{
                                  color: COLORS.primary,
                                  fontWeight: "bold",
                                }}
                                onClick={() => {
                                  navigate(COMMON_ROUTES.PRIVACY_POLICY);
                                }}
                              >
                                Privacy Policy
                              </Link>
                              {` and `}
                              <Link
                                style={{
                                  color: COLORS.primary,
                                  fontWeight: "bold",
                                }}
                                onClick={() => {
                                  navigate(COMMON_ROUTES.DISCLAIMER);
                                }}
                              >
                                Disclaimer
                              </Link>
                            </>
                          }
                          attrName="termsandconditions"
                          value={true}
                          defaultChecked={true}
                          // disabled={true}
                          variant="large"
                        />
                      </Grid>
                      <Grid size={12}>
                        <Box width="260px" margin="8px auto 0px">
                          <JDSButtons
                            className="primaryBtns large"
                            text="Get Free Quotes"
                            variant="text"
                            onClick={() => {
                              validateTerm();
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box className="why_jio_section">
          <Grid container className="row" columnSpacing={3} alignItems="center">
            <Grid size={12}>
              {/* <h4
              style={{
                color: COLORS.lightgrey,
                fontWeight: 700,
              }}
            >
              The Jio Advantage
            </h4> */}
              <h2>Why Jio Insurance?</h2>
              <h5
                style={{
                  color: "#595959",
                  maxWidth: "824px",
                }}
              >
                Choose from a wide range of life coverage plans that offer
                complete financial protection to your loved ones. It covers
                daily expenses, education, also helps pay mortgages, ensuring
                that your family is financially secure even when you are not
                around.
              </h5>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <img
                src="../images/jio-advantage.webp"
                width="100%"
                style={{ borderRadius: "24px" }}
                alt="img"
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <ul className="listView">
                {whySectionArray?.map((data, index) => (
                  <li key={index}>
                    <img src={data.iconUrl} alt="icon" />
                    <Box>
                      <h5
                        className="mb-1 mt-0"
                        style={{
                          color: COLORS.darkgrey,
                          fontWeight: "bold",
                        }}
                      >
                        {data.heading}
                      </h5>
                      <p
                        style={{
                          color: COLORS.lightgrey,
                        }}
                      >
                        {data.desc}
                      </p>
                    </Box>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Box>

        <Grid container className="row" rowSpacing={3} columnSpacing={6}>
          <Grid size={{ xs: 12, md: 6 }}>
            <h3 className="mb-4 mt-6">What is term insurance?</h3>
            <p>
              Term Insurance is a contract between an insurance company and an
              insured person. The insurer guarantees to pay a sum of money in
              lieu of premium, upon the demise of the insured person or on
              maturity of the policy.
            </p>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <img
              src="../images/term-insurance.webp"
              style={{ borderRadius: "24px" }}
              width="100%"
              alt=""
            />
          </Grid>
        </Grid>
        <Box className="lightGoldBg">
          <Grid container rowSpacing={3} columnSpacing={6}>
            <Grid size={12} textAlign="center">
              <h2>Types of life insurance plans</h2>
            </Grid>
            <Grid size={12} className="why_jio_section">
              <ul>
                {typesLifeinsuranceArray?.map((data, index) => (
                  <li key={index}>
                    <h4
                      className="mb-2"
                      style={{
                        color: COLORS.darkgrey,
                        fontWeight: "900",
                        lineHeight: "28px",
                        textAlign: "left",
                      }}
                    >
                      {data.heading}
                    </h4>
                    <p
                      style={{
                        color: COLORS.lightgrey,
                        fontSize: "14px",
                        lineHeight: "20px",
                      }}
                    >
                      {data.desc}
                    </p>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Box>

        {/* Blog articles */}
        {redirected ? null : (
          <Box className="blogs_section">
            <Grid
              container
              className="row"
              columnSpacing={3}
              marginBottom={"32px"}
            >
              <Grid size={12}>
                {/* <h4
              style={{
                color: "#595959",
              }}
            >
              Resource Hub
            </h4> */}
                <h2>Blogs</h2>
                <h5
                  style={{
                    color: "#595959",
                    maxWidth: "824px",
                  }}
                >
                  Securing your family's finances in your absence is of
                  paramount importance. Ensure to do a thorough comparison
                  before you select a certain plan for yourself.
                </h5>
              </Grid>
              <Grid size={12}>
                <ul>
                  {blogCardsArray?.map((data, index: any) => (
                    <BlogCard data={data} />
                  ))}
                </ul>
              </Grid>
              <Grid size={12} display={"flex"} justifyContent={"center"}>
                <Box maxWidth={"384px"} width={"100%"}>
                  <JDSButtons
                    text="Read more blogs"
                    variant={"text"}
                    className={"secondaryBtns large"}
                    onClick={() => navigate(COMMON_ROUTES.BLOGS)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        {/* Testimonial */}
        <Box className="testimonial_section">
          <Grid container className="row" columnSpacing={3}>
            <Grid size={12}>
              <h2 style={{ maxWidth: "520px", margin: "0px auto" }}>
                Meet some of our happy customers
              </h2>
            </Grid>
          </Grid>
          <Grid container className="row" columnSpacing={0}>
            <Grid size={12} className="testimonial-carousel">
              <Carousel
                responsive={responsive}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                partialVisible={false}
                removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                containerClass={`custom-carousel-track customCarouselTrack`}
                customTransition="transform 0.5s ease-in-out"
              >
                {testimonialsSlider.map((data, index) => (
                  <TestimonialCard data={data} />
                ))}
              </Carousel>
            </Grid>
          </Grid>
        </Box>

        {/* Partners */}
        <Box className="partners_section">
          <Grid container columnSpacing={3} marginBottom={"42px"}>
            <Grid size={12}>
              <h2 style={{ color: "#001E2B" }}>Our Partners</h2>
            </Grid>
          </Grid>
          <Grid container columnSpacing={3} justifyContent={"center"}>
            <Grid size={12} className="partners-carousel">
              <Carousel
                responsive={partersLogos}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                partialVisible={false}
                removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                containerClass={`custom-carousel-track customCarouselTrack`}
                customTransition="transform 0.5s ease-in-out"
              >
                {partnersSlider.map((data, index) => {
                  return (
                    <Box className="partner_box">
                      <img src={data.imgUrl} alt="" />
                    </Box>
                  );
                })}
              </Carousel>
            </Grid>
          </Grid>
        </Box>

        {/* faqs */}
        <Grid
          container
          className="row"
          rowSpacing={3}
          columnSpacing={6}
          justifyContent={"center"}
        >
          <Grid size={12} className="textCenter">
            <h2>Frequently Asked Questions</h2>
          </Grid>
          <Grid size={12}>
            <FAQ data={showAll ? faqs : faqs.slice(0, defaultShow)} />
          </Grid>
          <Grid size={12}>
            <JDSButtons
              onClick={() => setShowAll(!showAll)}
              className="secondaryBtns large"
              text={showAll ? "View less" : "View more"}
              variant="outlined"
              fullWidth={false}
              // loader="true"
            />
          </Grid>
        </Grid>

        {isMobile ? <MFooter /> : <Footer />}
      </Box>
    </>
  );
}

export default TermProductPage;
