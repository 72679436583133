import { subDays } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ADD_TW_DTO } from "../../../DTO/TWDTO/Form/AddTWDTO";
import TWForm from "../../../Page/Desktop/TW/Form/TWForm";
import MTWForm from "../../../Page/Mobile/TW/Form/MTWForm";
import { TW_ROUTES } from "../../../Router/Path/TWRoutes";
import { TW_ADD_FORM_SERVICES } from "../../../Services/TW/TWAddFormServices";
import { UserProfileSlice } from "../../../Store/Slice_Reducer/Account/UserProfileSlice";
import { TWSlice } from "../../../Store/Slice_Reducer/TwoWheeler/TWSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import {
  detectWebViewEnvironment,
  FORMAT_DATE_DD_MM_YYYY,
  FORMAT_YYYY_MM_DD,
  isEmpty,
  validateMobileNumber,
  validateRegno,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { FRONTEND_DOMAIN } from "../../../URLCollection/Domain";
import { TDropdown } from "../../../types/Common/TDropdown";
import { TTWForm } from "../../../types/TTWSlice";
import {
  pushDataLayer,
  pushMotorDataLayer,
  sendEventToNativeApp,
} from "../../../utils/analytics";
import { CAR_ADD_FORM_SERVICES } from "../../../Services/Car/CarAddFormServices";
import { COMMON_SERVICES } from "../../../Services/Common/CommonService";
import { SESSION_CONSTANTS } from "../../../Services/Constants/SessionConstants";

export default function TWFormContainer({
  open,
  setOpen,
  pageStatus,
  setPageStatus,
}: {
  open: boolean;
  setOpen: Function;
  pageStatus: number;
  setPageStatus: Function;
}) {
  const isMobile = useIsMobile();
  const [loader, setLoader] = useState<boolean>(false);
  const [vehicleInfoStatus, setVehicleInfoStatus] = useState<boolean>(false);
  const { ADD_FORM, REDIRECTED } = useAppSelector((state) => state.TW);
  const { TW } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const utm_source = searchParams.get("utm_source");

  useEffect(() => {
    if (open) {
      GET_RTO_LIST();
      GET_MAKE_LIST();
      GET_PREV_INSURER_LIST();
    }
  }, [open]);

  useEffect(() => {
    if (!isEmpty(ADD_FORM.make.value)) {
      GET_MODEL_LIST();
    }
  }, [ADD_FORM.make.value]);

  useEffect(() => {
    if (!isEmpty(ADD_FORM.make.value) && !isEmpty(ADD_FORM.model.value)) {
      GET_FUEL_TYPE_LIST();
    }
  }, [ADD_FORM.make.value, ADD_FORM.model.value]);

  useEffect(() => {
    if (
      !isEmpty(ADD_FORM.make.value) &&
      !isEmpty(ADD_FORM.model.value) &&
      !isEmpty(ADD_FORM.fuel_type.value)
    ) {
      GET_VARIANT_LIST();
    }
  }, [ADD_FORM.make.value, ADD_FORM.model.value, ADD_FORM.fuel_type.value]);

  const GET_PREV_INSURER_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          short_name?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        data.push({ label: "Do not remember", value: "Do not remember" });
        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.short_name === "string") {
            data.push({
              label: `${value.short_name}`,
              value: `${value.short_name}`,
            });
          }
        });

        dispatch(
          TWSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "PREVIOUS_INSURER_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    TW_ADD_FORM_SERVICES.GET_PREVIOUS_INSURER_LIST(onError, onSuccess);
  };

  const GET_RTO_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        const data: Array<{ label: string; value: string }> = [];

        Object.entries(response).map(([brand, value]) =>
          data.push({ label: `${brand} - ${value}`, value: brand })
        );

        dispatch(
          TWSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "RTO_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    TW_ADD_FORM_SERVICES.GET_RTO_LIST(onError, onSuccess);
  };

  const GET_MAKE_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          make?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.make === "string") {
            data.push({ label: `${value.make}`, value: `${value.make}` });
          }
        });

        dispatch(
          TWSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "MAKE_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    TW_ADD_FORM_SERVICES.GET_MAKE_LIST(onError, onSuccess);
  };

  const GET_MODEL_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          make_model?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.make_model === "string") {
            data.push({
              label: `${value.make_model}`,
              value: `${value.make_model}`,
            });
          }
        });

        dispatch(
          TWSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "MODEL_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    TW_ADD_FORM_SERVICES.GET_MODEL_LIST(
      onError,
      onSuccess,
      `${ADD_FORM.make.value}`
    );
  };

  const GET_FUEL_TYPE_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          fuel_type?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.fuel_type === "string") {
            data.push({
              label: `${value.fuel_type}`,
              value: `${value.fuel_type}`,
            });
          }
        });

        dispatch(
          TWSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "FUEL_TYPE_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    TW_ADD_FORM_SERVICES.GET_FUEL_TYPE_LIST(
      onError,
      onSuccess,
      `${ADD_FORM.make.value} ${ADD_FORM.model.value}`
    );
  };

  const GET_VARIANT_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          variant_cc?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.variant_cc === "string") {
            data.push({
              label: `${value.variant_cc}`,
              value: `${value.variant_cc}`,
            });
          }
        });

        dispatch(
          TWSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "VARIANT_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    TW_ADD_FORM_SERVICES.GET_VARIANT_LIST(
      onError,
      onSuccess,
      `${ADD_FORM.make.value} ${ADD_FORM.model.value}`,
      `${ADD_FORM.fuel_type.value}`
    );
  };

  const updateMasterState = (attrName: string, value: any) => {
    if (attrName === "reg_no") {
      dispatch(
        TWSlice.actions.ADD_FORM_DATA({
          ...ADD_FORM,
          [attrName]: { value: value, warning: !validateRegno(value) },
        })
      );
    } else if (attrName === "previous_insurer") {
      if (value === "Do not remember") {
        dispatch(
          TWSlice.actions.ADD_FORM_DATA({
            ...ADD_FORM,
            previous_insurer: { value: value, warning: isEmpty(value) },
            policy_expiry_date: {
              value: FORMAT_DATE_DD_MM_YYYY(subDays(new Date(), 1)),
              warning: false,
            },
            claimed: "No",
            prev_ncb: "0",
          })
        );
      } else {
        dispatch(
          TWSlice.actions.ADD_FORM_DATA({
            ...ADD_FORM,
            previous_insurer: { value: value, warning: isEmpty(value) },
          })
        );
      }
    } else if (attrName === "reg_date") {
      dispatch(
        TWSlice.actions.ADD_FORM_DATA({
          ...ADD_FORM,
          reg_date: { value: value, warning: isEmpty(value) },
          policy_expiry_date: { value: "", warning: false },
        })
      );
    } else if (attrName === "claimed") {
      if (value === "Yes") {
        dispatch(
          TWSlice.actions.ADD_FORM_DATA({
            ...ADD_FORM,
            [attrName]: value,

            prev_ncb: "0",
          })
        );
      } else {
        dispatch(
          TWSlice.actions.ADD_FORM_DATA({
            ...ADD_FORM,
            [attrName]: value,
          })
        );
      }
    } else if (attrName === "prev_ncb" || attrName === "business_type") {
      dispatch(
        TWSlice.actions.ADD_FORM_DATA({
          ...ADD_FORM,
          [attrName]: value,
        })
      );
    } else if (attrName === "make") {
      pushMotorDataLayer(
        "jioinsure_flow",
        "vehicle brand",
        `${value}`,
        "two wheeler",
        utm_source === "myjio"
          ? "MyJio app"
          : REDIRECTED
          ? "JFS app"
          : "Insure website"
      );
      sendEventToNativeApp("jioinsure_flow", {
        action: "vehicle brand",
        click: `${value}`,
        type: "two wheeler",
        EntrySource: REDIRECTED ? "JFS app" : "Insure website",
      });
      dispatch(
        TWSlice.actions.ADD_FORM_DATA({
          ...ADD_FORM,
          make: { value: value, warning: isEmpty(value) },
          model: { value: "", warning: false },
          make_model: { value: "", warning: false },
          fuel_type: { value: "", warning: false },
          variant_cc: { value: "", warning: false },
        })
      );
      if (!isEmpty(value)) {
        move_page("FORWORD");
      }
    } else if (attrName === "model") {
      pushMotorDataLayer(
        "jioinsure_flow",
        "vehicle model",
        `${value}`,
        "two wheeler",
        utm_source === "myjio"
          ? "MyJio app"
          : REDIRECTED
          ? "JFS app"
          : "Insure website"
      );
      sendEventToNativeApp("jioinsure_flow", {
        action: "vehicle model",
        click: `${value}`,
        type: "two wheeler",
        EntrySource: REDIRECTED ? "JFS app" : "Insure website",
      });
      dispatch(
        TWSlice.actions.ADD_FORM_DATA({
          ...ADD_FORM,
          model: {
            value: isEmpty(value) ? "" : value,
            warning: isEmpty(value),
          },
          make_model: {
            value: isEmpty(value) ? "" : value,
            warning: isEmpty(value),
          },
          fuel_type: { value: "", warning: false },
          variant_cc: { value: "", warning: false },
        })
      );
      if (!isEmpty(value)) {
        move_page("FORWORD");
      }
    } else if (attrName === "fuel_type") {
      pushMotorDataLayer(
        "jioinsure_flow",
        "fuel type",
        `${value}`,
        "two wheeler",
        utm_source === "myjio"
          ? "MyJio app"
          : REDIRECTED
          ? "JFS app"
          : "Insure website"
      );
      sendEventToNativeApp("jioinsure_flow", {
        action: "fuel type",
        click: `${value}`,
        type: "two wheeler",
        EntrySource: REDIRECTED ? "JFS app" : "Insure website",
      });
      dispatch(
        TWSlice.actions.ADD_FORM_DATA({
          ...ADD_FORM,
          fuel_type: { value: value, warning: isEmpty(value) },
          variant_cc: { value: "", warning: false },
        })
      );
      if (!isEmpty(value)) {
        move_page("FORWORD");
      }
    } else if (attrName === "variant_cc") {
      dispatch(
        TWSlice.actions.ADD_FORM_DATA({
          ...ADD_FORM,
          [attrName]: {
            value: isEmpty(value) ? "" : value,
            warning: isEmpty(value),
          },
        })
      );
      if (!isEmpty(value)) {
        move_page("FORWORD");
      }
    } else if (attrName === "rto") {
      pushMotorDataLayer(
        "jioinsure_flow",
        "RTO details",
        "proceed",
        "two wheeler",
        utm_source === "myjio"
          ? "MyJio app"
          : REDIRECTED
          ? "JFS app"
          : "Insure website"
      );
      sendEventToNativeApp("jioinsure_flow", {
        action: "RTO details",
        click: `proceed`,
        type: "two wheeler",
        EntrySource: REDIRECTED ? "JFS app" : "Insure website",
      });
      dispatch(
        TWSlice.actions.ADD_FORM_DATA({
          ...ADD_FORM,
          [attrName]: {
            value: isEmpty(value) ? "" : value,
            warning: isEmpty(value),
          },
        })
      );
      if (!isEmpty(value)) {
        move_page("FORWORD");
      }
    } else {
      dispatch(
        TWSlice.actions.ADD_FORM_DATA({
          ...ADD_FORM,
          [attrName]: { value: value, warning: isEmpty(value) },
        })
      );
    }
  };

  const move_page = (status: "FORWORD" | "BACKWORD") => {
    if (status === "BACKWORD") {
      if (ADD_FORM.business_type === "New") {
        if (pageStatus === 8) {
          setPageStatus(5);
        } else {
          setPageStatus((prev: number) => {
            return prev - 1;
          });
        }
      } else {
        setPageStatus((prev: number) => {
          return prev - 1;
        });
      }
    } else {
      setPageStatus((prev: number) => {
        if (status === "FORWORD" && prev < 8) {
          if (ADD_FORM.business_type === "New") {
            if (pageStatus === 5) {
              return 8;
            }
            return prev + 1;
          } else {
            return prev + 1;
          }
        } else {
          return prev;
        }
      });
    }
  };

  const validateForm = () => {
    let data: TTWForm = { ...ADD_FORM };
    if (pageStatus === 1) {
      if (ADD_FORM.business_type === "Rollover") {
      } else {
        data = {
          ...data,
          rto: {
            ...data.rto,
            warning: isEmpty(`${data.rto.value}`),
          },
        };

        if (!data.rto.warning) {
          move_page("FORWORD");
        }
      }
    } else if (pageStatus === 2) {
      data = {
        ...data,
        make: {
          ...data.make,
          warning: isEmpty(data.make.value),
        },
        make_model: {
          value: "",
          warning: false,
        },
      };

      if (!data.make.warning) {
        move_page("FORWORD");
      }
    } else if (pageStatus === 3) {
      data = {
        ...data,
        model: {
          ...data.model,
          warning: isEmpty(data.model.value),
        },
        make_model: {
          value: "",
          warning: false,
        },
      };

      if (!data.model.warning) {
        move_page("FORWORD");
      }
    } else if (pageStatus === 4) {
      data = {
        ...data,
        fuel_type: {
          ...data.fuel_type,
          warning: isEmpty(data.fuel_type.value),
        },
      };

      if (!data.fuel_type.warning) {
        move_page("FORWORD");
      }
    } else if (pageStatus === 5) {
      data = {
        ...data,
        variant_cc: {
          ...data.variant_cc,
          warning: isEmpty(data.variant_cc.value),
        },
      };

      if (!data.variant_cc.warning) {
        move_page("FORWORD");
      }
    } else if (pageStatus === 6) {
      if (data.business_type === "New") {
        data = {
          ...data,
          reg_date: {
            ...data.reg_date,
            warning: isEmpty(data.reg_date.value),
          },
          policy_expiry_date: {
            ...data.policy_expiry_date,
            warning: isEmpty(data.policy_expiry_date.value),
          },
          previous_insurer: {
            ...data.previous_insurer,
            warning: isEmpty(data.previous_insurer.value),
          },
        };

        if (
          !data.reg_date.warning &&
          !data.policy_expiry_date.warning &&
          !data.previous_insurer.warning
        ) {
          pushMotorDataLayer(
            "jioinsure_flow",
            "previous insurance details",
            "proceed",
            "two wheeler",
            utm_source === "myjio"
              ? "MyJio app"
              : REDIRECTED
              ? "JFS app"
              : "Insure website"
          );
          sendEventToNativeApp("jioinsure_flow", {
            action: "previous insurance details",
            click: `proceed`,
            type: "two wheeler",
            EntrySource: REDIRECTED ? "JFS app" : "Insure website",
          });
          move_page("FORWORD");
        }
      } else {
        if (data.previous_insurer.value === "Do not remember") {
          data = {
            ...data,
            reg_date: {
              ...data.reg_date,
              warning: isEmpty(data.reg_date.value),
            },
            previous_insurer: {
              ...data.previous_insurer,
              warning: isEmpty(data.previous_insurer.value),
            },
          };
          if (!data.reg_date.warning && !data.previous_insurer.warning) {
            pushMotorDataLayer(
              "jioinsure_flow",
              "claim history",
              "proceed",
              "two wheeler",
              utm_source === "myjio"
                ? "MyJio app"
                : REDIRECTED
                ? "JFS app"
                : "Insure website"
            );
            sendEventToNativeApp("jioinsure_flow", {
              action: "claim history",
              click: `proceed`,
              type: "two wheeler",
              EntrySource: REDIRECTED ? "JFS app" : "Insure website",
            });
            POST_TW_FORM();
          }
        } else {
          data = {
            ...data,
            reg_date: {
              ...data.reg_date,
              warning: isEmpty(data.reg_date.value),
            },
            policy_expiry_date: {
              ...data.policy_expiry_date,
              warning: isEmpty(data.policy_expiry_date.value),
            },
            previous_insurer: {
              ...data.previous_insurer,
              warning: isEmpty(data.previous_insurer.value),
            },
          };
          if (
            !data.reg_date.warning &&
            !data.policy_expiry_date.warning &&
            !data.previous_insurer.warning
          ) {
            pushMotorDataLayer(
              "jioinsure_flow",
              "previous insurance details",
              "proceed",
              "two wheeler",
              utm_source === "myjio"
                ? "MyJio app"
                : REDIRECTED
                ? "JFS app"
                : "Insure website"
            );
            sendEventToNativeApp("jioinsure_flow", {
              action: "previous insurance details",
              click: `proceed`,
              type: "two wheeler",
              EntrySource: REDIRECTED ? "JFS app" : "Insure website",
            });
            move_page("FORWORD");
          }
        }
      }
    } else if (pageStatus === 7) {
      POST_TW_FORM();
    } else if (pageStatus === 8) {
      data = {
        ...data,
        mobile: {
          ...data.mobile,
          warning: !validateMobileNumber(data.mobile.value),
        },
      };

      if (!data.mobile.warning) {
        pushMotorDataLayer(
          "jioinsure_flow",
          "contact details",
          "submit",
          "two wheeler",
          utm_source === "myjio"
            ? "MyJio app"
            : REDIRECTED
            ? "JFS app"
            : "Insure website"
        );
        sendEventToNativeApp("jioinsure_flow", {
          action: "contact details",
          click: `submit`,
          type: "two wheeler",
          EntrySource: REDIRECTED ? "JFS app" : "Insure website",
        });
        POST_TW_FORM();
      }
    }

    dispatch(TWSlice.actions.ADD_FORM_DATA(data));
  };

  const backFunction = () => {
    if (ADD_FORM.business_type === "New" && pageStatus === 1) {
      dispatch(
        TWSlice.actions.BULK_UPDATE({
          ...TW,
          ADD_FORM: {
            ...TWSlice.getInitialState().ADD_FORM,
            business_type: "Rollover",
            mobile: TW.ADD_FORM.mobile,
          },
        })
      );
    } else if (vehicleInfoStatus) {
      setPageStatus(2);
      setVehicleInfoStatus(false);
      dispatch(
        TWSlice.actions.ADD_FORM_DATA({
          ...ADD_FORM,
          make: { value: "", warning: false },
          model: { value: "", warning: false },
          make_model: { value: "", warning: false },
          fuel_type: { value: "", warning: false },
          variant_cc: { value: "", warning: false },
        })
      );
    } else {
      move_page("BACKWORD");
    }
  };

  const POST_TW_FORM = () => {
    const onSuccess = (res: any) => {
      const quote_no = res?.response;
      const error = res?.error;
      const message = res?.message;
      setLoader(false);
      if (!error) {
        if (detectWebViewEnvironment()) {
          sessionStorage.setItem(
            SESSION_CONSTANTS.TW_PAGE_STATUS,
            JSON.stringify(pageStatus)
          );
          sessionStorage.setItem(
            SESSION_CONSTANTS.TW_FORM_DATA,
            JSON.stringify(ADD_FORM)
          );
        }
        window.location.href = `${FRONTEND_DOMAIN}${TW_ROUTES.QUOTE}?type=TW&quote_no=${quote_no}&redirected=${TW.REDIRECTED}`;
      } else {
        toast.error(`${message}`);
      }
    };

    const onError = () => {
      setTimeout(() => {
        setLoader(false);
      }, 3000);
    };

    const data = {
      twBusinessType: ADD_FORM.business_type,
      twClaimMade: ADD_FORM.claimed,
      twFuelType: ADD_FORM.fuel_type.value,
      twMakeModel: `${ADD_FORM.make.value} ${ADD_FORM.model.value}`,
      twMobile: ADD_FORM.mobile.value,
      twEmail: ADD_FORM.email.value,
      twPExpiryDate: isEmpty(
        FORMAT_YYYY_MM_DD(ADD_FORM.policy_expiry_date.value)
      )
        ? ""
        : FORMAT_YYYY_MM_DD(ADD_FORM.policy_expiry_date.value),
      twPreviousInsurer: ADD_FORM.previous_insurer.value,
      twPreviousNCB: ADD_FORM.prev_ncb,
      twRegDate: isEmpty(FORMAT_YYYY_MM_DD(ADD_FORM.reg_date.value))
        ? ""
        : FORMAT_YYYY_MM_DD(ADD_FORM.reg_date.value),
      twRegNo: ADD_FORM.reg_no.value,
      twRtoCode:
        ADD_FORM.business_type === "New"
          ? `${ADD_FORM.rto.value}`.split("-")[0]
          : "",
      twVariantCC: ADD_FORM.variant_cc.value,
      twPincode: "",
      utm_source: REDIRECTED ? "JFS" : "DIRECT",
    };

    setLoader(true);
    TW_ADD_FORM_SERVICES.ADD_TW_DATA(onSuccess, onError, data);
  };

  let debounceTimeout: ReturnType<typeof setTimeout>;

  /**
   * useEffect hook to debounce the EVENT_TRACKER function call.
   * It triggers the EVENT_TRACKER function 2 seconds after the Car.ADD_FORM changes,
   * ensuring that rapid consecutive changes do not result in multiple calls.
   *
   * @returns A cleanup function that clears the timeout if the component is unmounted
   * or if the effect is re-triggered before the timeout completes.
   */
  useEffect(() => {
    clearTimeout(debounceTimeout);

    debounceTimeout = setTimeout(() => {
      EVENT_TRACKER();
    }, 2000);

    return () => clearTimeout(debounceTimeout);
  }, [TW?.ADD_FORM]);

  const EVENT_TRACKER = () => {
    const onSuccess = (res: any) => {};

    const onError = (err: any) => {
      console.log(err);
    };

    const data = {
      business_type: TW?.ADD_FORM.business_type,
      fuel_type: TW?.ADD_FORM.fuel_type.value,
      make_model: `${TW?.ADD_FORM.make.value} ${TW?.ADD_FORM.model.value}`,
      mobile_no: TW?.ADD_FORM.mobile.value,
      email: TW?.ADD_FORM.email.value,
      policy_expiry_date: FORMAT_YYYY_MM_DD(
        `${TW?.ADD_FORM.policy_expiry_date.value}`
      ),
      insurance_type: `Tw Insurance`,
      registration_no: TW?.ADD_FORM.reg_no.value,
      variant_cc: TW?.ADD_FORM.variant_cc.value,
      name: TW?.ADD_FORM.name.value,
    };
    COMMON_SERVICES.EVENT_TRACKER(onSuccess, onError, data);
  };

  return (
    <>
      {isMobile ? (
        <MTWForm
          loader={loader}
          open={open}
          setOpen={setOpen}
          backFunction={backFunction}
          pageStatus={pageStatus}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
        />
      ) : (
        <TWForm
          loader={loader}
          open={open}
          setOpen={setOpen}
          backFunction={backFunction}
          pageStatus={pageStatus}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
        />
      )}
    </>
  );
}
