import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import { store } from "./Store/Store";
import {
  FRONTEND_DOMAIN,
  NODE_DOMAIN,
  PHP_DOMAIN,
} from "./URLCollection/Domain";
import "./index.scss";
import "@evervent_pvt_ltd/ui-kit/dist/index.css";
import reportWebVitals from "./reportWebVitals";
import { initializeGA } from "./utils/analytics";

// GTM Initialization and DataLayer Push
const injectGTM = () => {
  // Create the GTM script element dynamically
  const script = document.createElement("script");
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtm.js?id=GTM-NHS3BX2R`; // Replace with your GTM Container ID

  // Append to the head
  document.head.appendChild(script);

  // Initialize the dataLayer if not already initialized
  window.dataLayer = window.dataLayer || [];

  // Push global variables to the dataLayer
  window.dataLayer.push({
    "gtm.start": new Date().getTime(),
    event: "gtm.js",
    EntrySource: getEntrySource(), // Function to determine the source dynamically
    app_environment: getAppEnvironment(), // Function to get the environment
  });
};

// Function to determine the EntrySource (for example from UTM params or localStorage)
const getEntrySource = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const utm_source = urlParams.get("utm_source") || "";
  return utm_source === "myjio" ? "MyJio app" : "Insure website";
};

// Function to get the AppEnvironment (PROD, SIT, etc.)
const getAppEnvironment = () => {
  return process.env.REACT_APP_ENV === "PROD" ||
    process.env.REACT_APP_ENV === `"PROD"`
    ? "prod"
    : "sit";
};

injectGTM();

Sentry.init({
  dsn: "https://9654e66747845bd4f2b8eae178ceac16@o4506864219521024.ingest.us.sentry.io/4506904009703424",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [FRONTEND_DOMAIN, PHP_DOMAIN, NODE_DOMAIN],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const trackingID = "G-CVPD2K66Y3";
// const trackingID = "G-D02CNQL5S9"; // Replace with your actual tracking ID
initializeGA(trackingID);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ToastContainer theme="colored" />
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
