import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import {
  blog_data,
  blogsResponsive,
} from "../../../../Component/Desktop/Common/BlogArray/BLogArray";
import Footer from "../../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../../Component/Mobile/Common/MNavBar/MNavBar";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import "./../BlogsPage.scss";
import HelmetComponent from "../../../../SupportingFiles/HelmetComponent";
import { FRONTEND_DOMAIN } from "../../../../URLCollection/Domain";

const BlogMakingUnderstanding = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const current_blog_data = {
    id: "making-understanding-of-idv-easy-for-you!",
    blogtitle: "Making understanding of IDV easy for you!",
    img_url: "/images/blog5.webp",
    main_desc: (
      <>
        <h6 className="mb-3">What is IDV value in a two-wheeler insurance?</h6>
        <p className="mb-4">
          While purchasing your two-wheeler insurance, you must have wondered
          what IDV is and how it affects your insurance premium. Insured
          Declared Value (IDV) is the maximum amount of insurance that the
          insurance company will pay out to the policyholder in the event that
          the insured vehicle is stolen or damaged in an accident and plays a
          crucial role when purchasing{" "}
          <a href="/two-wheeler-insurance">two-wheeler insurance.</a> Typically,
          it refers to a two-wheeler's current market value. For e.g. the
          insurance policy will only pay out a maximum of ₹ 50,000/- if the
          two-wheeler's current market worth is ₹ 50,000/-.
        </p>
      </>
    ),
    blog_full_desc: (
      <>
        <h6 className="mb-3">Understanding depreciation rates</h6>
        <p className="mb-3">
          Another word that is frequently used when determining the premium
          amount is depreciation. The vehicle's value declines with time. As a
          result, when determining your payout amount, depreciation is modified.
        </p>
        <p className="mb-3">
          The following table explains how depreciation affects Two-Wheeler
          insurance IDV –
        </p>
        <table className="mb-5 tabledata">
          <thead>
            <tr>
              <th>Age of the two-wheeler </th>
              <th>Depreciation percentage deducted to calculate IDV</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Six months old or lesser </td>
              <td>5%</td>
            </tr>
            <tr>
              <td>Between 6 months to 1 year </td>
              <td>15%</td>
            </tr>
            <tr>
              <td>Between 1 year to 2 years </td>
              <td>20%</td>
            </tr>
            <tr>
              <td>Between 2 years to 3 years </td>
              <td>30%</td>
            </tr>
            <tr>
              <td>Between 3 years to 4 years </td>
              <td>40%</td>
            </tr>
            <tr>
              <td>Between 4 years to 5 years </td>
              <td>50%</td>
            </tr>
          </tbody>
        </table>
        <h6 className="mb-3">Age five and over IDV for a two-wheeler</h6>
        <p className="mb-5">
          The computed rate of depreciation of a two-wheeler is directly
          inversely correlated to the age of the vehicle; that is, the older the
          two-wheeler, the lower its value. A two-wheeler's depreciated value is
          calculated for models that are five years old or older depending on
          the vehicle's usable condition and the state of its body parts. The
          final IDV may be agreed upon between the insurance provider and the
          policyholder in the case of a two-wheeler that is more than five years
          old or an outmoded model. Some insurance providers use the method of
          hiring surveyors to calculate the accurate IDV for insurance.{" "}
        </p>
        <h6 className="mb-3">
          Factors Affecting the Insured Declared Value (IDV)
        </h6>
        <p>
          IDV in a two-wheeler insurance is determined based on the following
          factors –
        </p>
        <ul className="mb-5">
          <li>The age of the Two-Wheeler to be insured </li>
          <li>Two-Wheeler registration date </li>
          <li>City of registration</li>
          <li>Fuel type </li>
          <li>Make, model and variant </li>
          <li>Two-Wheeler insurance policy tenure </li>
        </ul>

        <h6 className="mb-3">Significance of IDV in two-wheeler insurance</h6>
        <p className="mb-5">
          As discussed before, IDV of a Two-Wheeler means the amount of
          compensation the policyholder receives in case of an unforeseen event.
          It is wise to get IDV closest to the Two-Wheeler's market value. This
          ensures that if an accident happens or the insured vehicle is stolen,
          the owner is compensated fairly enough and does not have to suffer a
          major loss. Sometimes, insurers may offer to bring down the IDV by
          about 5-10%. However, this percentage may be chosen by the customer.
          Hence, a lower IDV value means a lower rate of premium and vice-versa.
        </p>

        <h6 className="mb-3">Effects of selecting the wrong IDV </h6>
        <p>
          The IDV of your two-wheeler directly affects the cost of your
          insurance. If you decide to lower the IDV, the following things will
          occur:{" "}
        </p>
        <ul className="mb-5">
          <li>
            As a result, the insurance coverage will be less and in the event
            that the Two-Wheeler is totaled, the claim amount will also be less.{" "}
          </li>
          <li>
            You will be required to cover the additional expense out of your own
            pocket if the cost of restoring the accidental damage is higher.{" "}
          </li>
          <li>
            Because of the reduced claim amount, you will ultimately incur a
            great loss.{" "}
          </li>
        </ul>
        <p>
          However, if you raise the IDV, you will be responsible for the
          following:
        </p>
        <ul className="mb-5">
          <li>Your Two-Wheeler insurance rate will go up as a result.</li>
          <li>
            You might not be able to file a claim for a total loss if you do
            this.
          </li>
          <li>
            As a result, it is crucial that you state your two-wheeler's
            accurate IDV. You can start by doing extensive research before
            purchasing a two-wheeler insurance policy. Visit a few websites that
            sell used or pre-owned two-wheelers to check the market for those as
            a starting point. By doing so, you'll have a general notion of what
            your two-wheeler's IDV should be.
          </li>
        </ul>

        <h6 className="mb-3">In a nutshell</h6>
        <p className="mb-5">
          One of the most important considerations for determining your
          two-wheeler insurance price is IDV. You can compare multiple
          Two-Wheeler insurance quotes provided by various insurers{" "}
          <a href="/?utm_source=website_blog&utm_medium=Blog_understandingIDV&utm_campaign=onourwebsite&utm_id=interlinking">
            on our website,
          </a>{" "}
          which aids in helping you select the best policy for your two-wheeler.
          At{" "}
          <span style={{ fontWeight: "900" }}>
            Jio Insurance Broking Limited
          </span>{" "}
          our endeavor is to provide you with best in class products at
          competitive rates and in a hassle free way!{" "}
        </p>
      </>
    ),
  };

  const blogsJsonLd = JSON.stringify(
    {
      "@context": "https://schema.org",
      "@type": "Article",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": `${FRONTEND_DOMAIN}/blog/making-understanding-of-idv-easy-for-you`,
      },
      headline: current_blog_data?.blogtitle,
      image: `${FRONTEND_DOMAIN}${current_blog_data?.img_url}`,
      author: {
        "@type": "Organization",
        name: "Jio Insurance Broking Ltd.",
        url: `${FRONTEND_DOMAIN}/about-us`,
      },
      datePublished: "2024-10-22",
      dateModified: "2024-10-22",
    },
    null,
    2
  ); // Added 'null, 2' to format it with indentation

  return (
    <Box className="blogsWrapper">
      <HelmetComponent
        title="Everything you need to know about IDV in bike insurance"
        description="Learn more about the insured declared value (IDV) in bike insurance, its calculation, role, and importance for your policy to ensure you have adequate coverage."
        showCanonicalTag={true}
        scripts={[blogsJsonLd]}
      />
      {isMobile ? <MNavBar /> : <NavBar />}
      <Box className="blogsdetail-sec">
        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}
        >
          <Grid size={12}>
            <h2>{current_blog_data?.blogtitle}</h2>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              marginBottom={"24px"}
            >
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: "normal",
                  textTransform: "uppercase",
                  fontWeight: "700",
                }}
              >
                Sep 30, 2024
              </p>
              <Box display={"flex"} gap={"8px"}>
                <span className="tag">Insurance</span>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}
        >
          <Grid size={6} marginBottom={"24px"}>
            <Box className="img-overlay">
              <Box className="img-bg">
                <img src={current_blog_data?.img_url} alt="" />
              </Box>
            </Box>
          </Grid>
          <Grid size={6} marginBottom={"24px"}>
            {current_blog_data?.main_desc}
          </Grid>
          <Grid size={12} marginBottom={"24px"}>
            {current_blog_data?.blog_full_desc}
          </Grid>
        </Grid>
      </Box>
      <Box className="blogCard-sec mb-pr-0">
        <Grid
          container
          className="row"
          columnSpacing={3}
          justifyContent={"center"}
        >
          <Grid size={12} marginBottom={"24px"} className="mb-pr-0">
            <h4>You May Also Like</h4>
          </Grid>
          <Grid size={12} className="mb-pr-0">
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={blogsResponsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              arrows={false}
              customTransition="transform 0.7s ease-in-out"
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              partialVisible={true}
            >
              {blog_data.map((item, index) => (
                <Box>
                  <Box
                    className="blog-card"
                    sx={{
                      boxShadow: "0px 4px 16px rgba(0,0,0,0.08)",
                      borderRadius: "12px",
                      marginRight: "16px",
                    }}
                  >
                    <img src={item?.img_url} alt="" />
                    <Box className="card-info">
                      <h5 className="mb-1">{item.blogtitle}</h5>
                      <p className="mb-3">{item.blog_desc}</p>
                      <Link
                        className="cont-read-link"
                        onClick={() => {
                          navigate(item?.id);
                          window.scrollTo(0, 0);
                        }}
                      >
                        Continue reading
                      </Link>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default BlogMakingUnderstanding;
