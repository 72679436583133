import MNonTermPolicyDetails from "../../../Component/Mobile/NonTerm/MNonTermQuoteCard/MNonTermPolicyDetails/MNonTermPolicyDetails";
import NonTermQuotePolicyDetail from "../../../Page/Desktop/NonTerm/NonTermQuote/NonTermPolicyDetails/NonTermPolicyDetails";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TNonTermProduct } from "../../../types/NonTerm/TNonTermCardProduct";

const NonTermPolicyDetailsContainer = ({
  open,
  setOpen,
  data,
  BUY_ACTION,
  openEbi = false,
  setOpenEbi = () => {},
}: {
  open: boolean;
  setOpen: Function;
  data: TNonTermProduct;
  BUY_ACTION: Function;
  openEbi?: boolean;
  setOpenEbi?: Function;
}) => {
  const isMobile = useIsMobile();

  const exclusions = [
    "In case of death due to suicide within 12 months from the date of commencement of risk under the policy or from the date of revival of the policy, as applicable, the nominee or beneficiary of the policyholder shall be entitled to at least 80% of the total premiums paid till the date of death or the surrender value available as on the date of death whichever is higher, provided the policy is in force.",
  ];

  const exclusions_tata = [
    "Fulfil long term goals like – child education, second income or retirement planning",
  ];
  const unique_features = [
    "Guaranteed Income - Get a guaranteed income for a period of 25 or 30 years after maturity of the plan.",
    "Assured benefits: The returns that you get are completely guaranteed and insulated from all kinds of market-risks.",
    "Get tax free income to choose guaranteed benefits as regular income payout frequency (Yearly, Half-Yearly, Quarterly and Monthly) ",
  ];
  const tax_benefits = [
    "Income tax benefit on the premium paid as per Section 80C and on the claims received as per Section 10(10D) of the Income Tax Act.",
  ];

  const unique_features_tata = [
    "1.	Get guaranteed tax-free income for investments upto 5 L",
    "2.	Returns are 100% insulated from all kinds of market-risks",
    "3. Get Guaranteed Income with life cover",
    "4.	Get your premiums back at the end of the policy term",
  ];

  const POLICY_DETAILS = {
    exclusions,
    unique_features,
    tax_benefits,
    unique_features_tata,
    exclusions_tata,
  };

  return (
    <>
      {isMobile ? (
        <MNonTermPolicyDetails
          BUY_ACTION={BUY_ACTION}
          data={data}
          open={open}
          setOpen={setOpen}
          POLICY_DETAILS={POLICY_DETAILS}
          openEbi={openEbi}
          setOpenEbi={setOpenEbi}
        />
      ) : (
        <NonTermQuotePolicyDetail
          BUY_ACTION={BUY_ACTION}
          data={data}
          open={open}
          setOpen={setOpen}
          POLICY_DETAILS={POLICY_DETAILS}
          openEbi={openEbi}
          setOpenEbi={setOpenEbi}
        />
      )}
    </>
  );
};
export default NonTermPolicyDetailsContainer;
