import { Box, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid2";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { COMMON_SERVICES } from "../../Services/Common/CommonService";
import { useAppSelector } from "../../Store/hooks";
import {
  isEmpty,
  validateFullName,
  validateMobileNumber,
} from "../../SupportingFiles/HelpingFunction";
import { COLORS } from "../../SupportingFiles/colors";
import { TFieldData } from "../../types/Common/TFieldData";
import JDSButtons from "../InputFields/JDSButtons/JDSButtons";
import SelectDropdown from "../InputFields/SelectDropdown/SelectDropdown";
import TextInputField from "../InputFields/TextInputField/TextInputField";

function ScheduleCallPopup({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Function;
}) {
  const [scheduleStep, setScheduleStep] = useState(false);
  const [callNowStep, setCallNowStep] = useState<"1" | "2">("1");
  const [scheduleCallStep, setScheduleCallStep] = useState<"1" | "2">("1");
  const [scroll, setScroll] = useState<"body">("body");
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [isDropdownVisible, setIsDropdownVisible] = useState(true);
  const productTypes = [
    { label: "Health", value: "/health-insurance" },
    { label: "Health", value: "/landing-page/health-insurance" },
    { label: "Car", value: "/car-insurance" },
    { label: "Car", value: "/landing-page/car-insurance" },
    { label: "Two Wheeler", value: "/two-wheeler-insurance" },
    { label: "Two Wheeler", value: "/landing-page/two-wheeler-insurance" },
    { label: "Term", value: "/term-insurance" },
    { label: "Term", value: "/landing-page/term-insurance" },
  ];
  const showProductTypes = [
    { label: "Health", value: "/health-insurance" },
    { label: "Car", value: "/car-insurance" },
    { label: "Two Wheeler", value: "/two-wheeler-insurance" },
    { label: "Term", value: "/term-insurance" },
  ];

  const today = dayjs();
  const times = [
    "10:00 - 12:00",
    "12:00 - 14:00",
    "14:00 - 16:00",
    "16:00 - 18:00",
    "18:00 - 20:00",
  ];
  const getFilteredTimes = () => {
    if (selectedDate) {
      const isToday = dayjs(selectedDate).isSame(today, "day");
      if (isToday) {
        const currentHour = dayjs().hour();
        return times.filter((time) => {
          const startHour = parseInt(time.split(" - ")[0].split(":")[0], 10);
          return startHour > currentHour;
        });
      }
    }
    return times;
  };

  const dates = Array.from({ length: 7 }, (_, i) => {
    const date = dayjs().add(i, "day");
    return {
      day: date.format("ddd").toUpperCase(),
      displayDate: date.format("DD"),
      fullDate: date.format("YYYY-MM-DD"),
    };
  });

  useEffect(() => {
    // Set the first element as the default selected date when the component mounts
    if (dates && dates.length > 0) {
      setSelectedDate(dates[0].fullDate);
    }
  }, [open]);

  const handleDateClick = (date: string) => {
    setSelectedDate(date);
  };

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    const hours = `0${date.getHours()}`.slice(-2);
    const minutes = `0${date.getMinutes()}`.slice(-2);
    const seconds = `0${date.getSeconds()}`.slice(-2);
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleTimeClick = (time: any) => {
    setSelectedTime(time);
  };

  const [shareURLData, setShareURLData] = useState<{
    name: TFieldData;
    mobile: TFieldData;
    product?: { warning: boolean };
  }>({
    name: { value: "", warning: false },
    mobile: { value: "", warning: false },
  });

  const [linkLoad, setLinkLoad] = useState<boolean>(false);
  const [type, setType] = useState("");

  const handleDetails = (attrName: any, value: any) => {
    if (attrName === "name") {
      setShareURLData((prev) => ({
        ...prev,
        name: { value: value, warning: !validateFullName(value) },
      }));
    } else if (attrName === "mobile") {
      setShareURLData((prev) => ({
        ...prev,
        mobile: { value: value, warning: !validateMobileNumber(value) },
      }));
    }
  };

  const CALL_ADVISOR = () => {
    setLinkLoad(true);
    let data = { ...shareURLData };
    console.log("data", data);

    if (!isEmpty(data.name.value)) {
      data = {
        ...data,
        name: { ...data.name, warning: !validateFullName(data.name.value) },
      };
    } else {
      data = {
        ...data,
        name: { ...data.name, warning: true },
      };
    }

    if (!isEmpty(data.mobile.value)) {
      data = {
        ...data,
        mobile: {
          ...data.mobile,
          warning: !validateMobileNumber(data.mobile.value),
        },
      };
    } else {
      data = {
        ...data,
        mobile: {
          ...data.mobile,
          warning: true,
        },
      };
    }

    if (isDropdownVisible && isEmpty(selectedProduct)) {
      data = {
        ...data,
        product: { warning: true },
      };
    } else {
      data = {
        ...data,
        product: { warning: false },
      };
    }

    setShareURLData(data);

    const hasError =
      data.name.warning ||
      data.mobile.warning ||
      (isDropdownVisible && isEmpty(selectedProduct));

    if (hasError) {
      toast.error("Please fill all mandatory fields correctly.");
      setLinkLoad(false);
      return;
    }

    const onSuccess = (res: any) => {
      setLinkLoad(false);
      setCallNowStep("2");
      console.log("result", res);
      setShareURLData({
        name: { value: "", warning: false },
        mobile: { value: "", warning: false },
        product: { warning: false },
      });
    };

    const onError = (err: any) => {
      console.log("error", err);
      setLinkLoad(false);
    };

    let param = {
      name: data.name.value,
      mobile: data?.mobile?.value,
      insurance_type:
        selectedProduct === "/health-insurance" ||
        selectedProduct === "/landing-page/health-insurance"
          ? "Health Insurance"
          : selectedProduct === "/car-insurance" ||
            selectedProduct === "/landing-page/car-insurance"
          ? "Car Insurance"
          : selectedProduct === "/two-wheeler-insurance" ||
            selectedProduct === "/landing-page/two-wheeler-insurance"
          ? "Tw Insurance"
          : "Term Insurance",
    };

    COMMON_SERVICES.CLICK_TO_CALL(onSuccess, onError, param);
  };

  const handleScheduleValidation = () => {
    setLinkLoad(true);
    let data = { ...shareURLData };
    console.log("data", data);

    if (!isEmpty(data.name.value)) {
      data = {
        ...data,
        name: { ...data.name, warning: !validateFullName(data.name.value) },
      };
    } else {
      data = {
        ...data,
        name: { ...data.name, warning: true },
      };
    }

    if (!isEmpty(data.mobile.value)) {
      data = {
        ...data,
        mobile: {
          ...data.mobile,
          warning: !validateMobileNumber(data.mobile.value),
        },
      };
    } else {
      data = {
        ...data,
        mobile: {
          ...data.mobile,
          warning: true,
        },
      };
    }

    if (isDropdownVisible && isEmpty(selectedProduct)) {
      data = {
        ...data,
        product: { warning: true },
      };
    } else {
      data = {
        ...data,
        product: { warning: false },
      };
    }

    setShareURLData(data);

    const hasError =
      data.name.warning ||
      data.mobile.warning ||
      (isDropdownVisible && isEmpty(selectedProduct));

    if (isEmpty(data.name.value) || hasError) {
      toast.error("Please fill all mandatory fields correctly.");
      setLinkLoad(false);
      return;
    }

    setScheduleStep(true);
    setLinkLoad(false);
  };

  const SCHEDULE_CALL = () => {
    setLinkLoad(true);
    let data = { ...shareURLData };
    console.log("data", data);

    if (!isEmpty(data.name.value)) {
      data = {
        ...data,
        name: { ...data.name, warning: !validateFullName(data.name.value) },
      };
    } else {
      data = {
        ...data,
        name: { ...data.name, warning: true },
      };
    }

    if (!isEmpty(data.mobile.value)) {
      data = {
        ...data,
        mobile: {
          ...data.mobile,
          warning: !validateMobileNumber(data.mobile.value),
        },
      };
    } else {
      data = {
        ...data,
        mobile: {
          ...data.mobile,
          warning: true,
        },
      };
    }

    setShareURLData(data);

    const hasError = data.name.warning;

    if (isEmpty(data.name.value)) {
      toast.error("Please fill all mandatory fields correctly.");
      setLinkLoad(false);
      return;
    }

    if (hasError) {
      setLinkLoad(false);
      return;
    }

    const onSuccess = (res: any) => {
      // handle success
      setLinkLoad(false);
      setScheduleCallStep("2");
      console.log("result", res);
      setShareURLData({
        name: { value: "", warning: false },
        mobile: { value: "", warning: false },
      });
    };

    const onError = (err: any) => {
      // handle error
      setLinkLoad(false);
    };

    if (selectedDate && selectedTime) {
      const selectedDateTime = new Date(selectedDate);
      const hours = parseInt(selectedTime.slice(0, 2), 10);
      const minutes = parseInt(selectedTime.slice(3, 5), 10);
      selectedDateTime.setHours(hours);
      selectedDateTime.setMinutes(minutes);
      const payload = {
        name: data.name.value,
        mobile: data.mobile.value,
        insurance_type:
          selectedProduct === "/health-insurance" ||
          selectedProduct === "/landing-page/health-insurance"
            ? "Health Insurance"
            : selectedProduct === "/car-insurance" ||
              selectedProduct === "/landing-page/car-insurance"
            ? "Car Insurance"
            : selectedProduct === "/two-wheeler-insurance" ||
              selectedProduct === "/landing-page/two-wheeler-insurance"
            ? "Tw Insurance"
            : "Term Insurance",
        timestamp: formatDate(selectedDateTime),
      };

      COMMON_SERVICES.SCHEDULE(onSuccess, onError, payload);
    } else {
      toast.error("Please select both a date and time");
      setLinkLoad(false);
    }
  };

  const handleProductChange = (value: string, attrName: string) => {
    setSelectedProduct(attrName);

    setShareURLData((prev) => ({
      ...prev,
      product: { warning: false },
    }));
  };

  useEffect(() => {
    const path = window.location.pathname;
    setType(path);
    const matchingProduct = productTypes.find((product) => {
      return product.value === path;
    });
    if (matchingProduct) {
      setSelectedProduct(matchingProduct.value);
      setIsDropdownVisible(false);
    } else {
      setIsDropdownVisible(true);
    }
  }, [window.location.pathname, open]);

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      className="modal-scrollable"
    >
      <DialogContent
        dividers={false}
        className="modal-content xsWidth scheduleCallPopup px-6"
      >
        <Grid className="topbar" container alignItems="center">
          <Grid size={12} textAlign={"right"}>
            <Button
              disableRipple
              className="closePopup"
              onClick={() => {
                setOpen(false);
                setLinkLoad(false);
                setSelectedProduct("");
                setSelectedDate(null);
                setSelectedTime(null);
                setShareURLData({
                  name: { value: "", warning: false },
                  mobile: { value: "", warning: false },
                });
                setTimeout(() => {
                  setCallNowStep("1");
                  setScheduleCallStep("1");
                  setScheduleStep(false);
                }, 1000);
              }}
            />
          </Grid>
        </Grid>
        {!scheduleStep ? (
          <>
            {callNowStep === "1" ? (
              <Grid container spacing={3}>
                <Grid size={12}>
                  <h3 className="mb-2">Add your details</h3>
                  <p style={{ color: COLORS.lightgrey }}>
                    We promise you won’t find a better price from our partners
                    anywhere else.
                  </p>
                </Grid>
                <Grid size={12}>
                  <TextInputField
                    class_name="inputField"
                    title={"Full name"}
                    value={shareURLData.name.value}
                    placeholder="Eg: John Smith"
                    attrName={"name"}
                    value_update={handleDetails}
                    error_message={
                      !isEmpty(shareURLData.name.value)
                        ? "Enter Full Name"
                        : "Enter Valid Full Name"
                    }
                    warn_status={shareURLData.name.warning}
                    validation_type="NAME"
                  />
                </Grid>
                <Grid size={12}>
                  <TextInputField
                    class_name="inputField"
                    title={"Mobile number"}
                    value={shareURLData.mobile.value}
                    placeholder="XXXXXXXXXX"
                    attrName={"mobile"}
                    value_update={handleDetails}
                    error_message={
                      !isEmpty(shareURLData.mobile.value)
                        ? "Enter Mobile Number"
                        : "Enter Valid Mobile Number"
                    }
                    warn_status={shareURLData.mobile.warning}
                    validation_type="NUMBER"
                    max_length={10}
                  />
                </Grid>
                {isDropdownVisible && (
                  <Grid size={12}>
                    <SelectDropdown
                      title="Product I want"
                      data={showProductTypes}
                      attrName="selectedProduct"
                      class_name={
                        shareURLData.product?.warning
                          ? "inputField error"
                          : "inputField"
                      }
                      info_message=""
                      error_message={
                        shareURLData.product?.warning
                          ? "Please select a product"
                          : ""
                      }
                      value={selectedProduct}
                      value_update={handleProductChange}
                      warn_status={shareURLData.product?.warning || false}
                    />
                  </Grid>
                )}
                <Grid size={12}>
                  <JDSButtons
                    className="primaryBtns large"
                    text="Call me now"
                    variant="text"
                    loader={linkLoad}
                    onClick={() => {
                      CALL_ADVISOR();
                    }}
                  />
                </Grid>
                <Grid size={12}>
                  <JDSButtons
                    className="tertiaryBtns large"
                    text="Schedule a call"
                    variant="text"
                    loader={false}
                    onClick={() => {
                      handleScheduleValidation();
                    }}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={3}>
                <Grid size={12}>
                  <svg
                    width="40"
                    height="41"
                    viewBox="0 0 40 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 0.972534C16.0444 0.972534 12.1776 2.14551 8.8886 4.34314C5.59962 6.54077 3.03617 9.66434 1.52242 13.3189C0.00866567 16.9734 -0.387401 20.9947 0.384303 24.8743C1.15601 28.754 3.06082 32.3176 5.85787 35.1147C8.65492 37.9117 12.2186 39.8165 16.0982 40.5882C19.9778 41.3599 23.9992 40.9639 27.6537 39.4501C31.3082 37.9364 34.4318 35.3729 36.6294 32.0839C38.827 28.795 40 24.9282 40 20.9725C40 18.3461 39.4827 15.7454 38.4776 13.3189C37.4725 10.8924 35.9993 8.68757 34.1421 6.8304C32.285 4.97323 30.0802 3.50004 27.6537 2.49494C25.2272 1.48985 22.6264 0.972534 20 0.972534ZM30.42 16.3925L18.42 28.3925C18.2341 28.58 18.0129 28.7288 17.7692 28.8303C17.5254 28.9319 17.264 28.9841 17 28.9841C16.736 28.9841 16.4746 28.9319 16.2309 28.8303C15.9871 28.7288 15.7659 28.58 15.58 28.3925L9.58001 22.3925C9.39353 22.2061 9.24561 21.9847 9.14469 21.741C9.04377 21.4974 8.99183 21.2363 8.99183 20.9725C8.99183 20.4399 9.2034 19.9291 9.58001 19.5525C9.95662 19.1759 10.4674 18.9644 11 18.9644C11.5326 18.9644 12.0434 19.1759 12.42 19.5525L17 24.1525L27.58 13.5525C27.9566 13.1759 28.4674 12.9644 29 12.9644C29.5326 12.9644 30.0434 13.1759 30.42 13.5525C30.7966 13.9291 31.0082 14.4399 31.0082 14.9725C31.0082 15.5051 30.7966 16.0159 30.42 16.3925Z"
                      fill="#25AB21"
                    />
                  </svg>

                  <h3 className="my-2" style={{ fontWeight: "bold" }}>
                    An expert will call you as soon as possible
                  </h3>
                  <p style={{ color: COLORS.lightgrey }}>
                    You will receive a call from our insurance expert to help
                    you choose the suitable plan for you.
                  </p>
                </Grid>
              </Grid>
            )}{" "}
          </>
        ) : (
          <>
            {scheduleCallStep === "1" ? (
              <Grid container spacing={3}>
                <Grid size={12}>
                  <h3 className="mb-2">Schedule a call</h3>
                </Grid>
                <Grid size={12}>
                  <Box className="date-selector">
                    {dates.map((item, index) => (
                      <div key={index}>
                        <span>{item.day}</span>
                        <span
                          className={`date-item ${
                            (selectedDate && selectedDate === item.fullDate) ||
                            (index === 0 && !selectedDate)
                              ? "selected"
                              : ""
                          }`}
                          onClick={() => handleDateClick(item.fullDate)}
                        >
                          {item.displayDate}
                        </span>
                      </div>
                    ))}
                  </Box>

                  <Box className="time-selector">
                    <h4 className="mb-2" style={{ fontSize: "18px" }}>
                      Pick your preferred time
                    </h4>

                    {selectedDate && selectedTime ? (
                      <p className="mb-4">
                        {dayjs(selectedDate).format("dddd, MMMM D")}
                      </p>
                    ) : (
                      ""
                    )}
                    {getFilteredTimes().map((time: any, index: number) => (
                      <div
                        key={index}
                        className={`time-slot ${
                          selectedTime === time ? "selected" : ""
                        }`}
                        // onClick={() => setSelectedTime(time)}
                        onClick={() => handleTimeClick(time)}
                      >
                        {time}
                      </div>
                    ))}
                  </Box>
                </Grid>
                <Grid size={12}>
                  <JDSButtons
                    className="primaryBtns large"
                    text="Book now "
                    variant="text"
                    loader={false}
                    onClick={() => {
                      // setScheduleCallStep("2");
                      // setScroll("body");
                      SCHEDULE_CALL();
                    }}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={3}>
                <Grid size={12}>
                  <svg
                    width="40"
                    height="41"
                    viewBox="0 0 40 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 0.972534C16.0444 0.972534 12.1776 2.14551 8.8886 4.34314C5.59962 6.54077 3.03617 9.66434 1.52242 13.3189C0.00866567 16.9734 -0.387401 20.9947 0.384303 24.8743C1.15601 28.754 3.06082 32.3176 5.85787 35.1147C8.65492 37.9117 12.2186 39.8165 16.0982 40.5882C19.9778 41.3599 23.9992 40.9639 27.6537 39.4501C31.3082 37.9364 34.4318 35.3729 36.6294 32.0839C38.827 28.795 40 24.9282 40 20.9725C40 18.3461 39.4827 15.7454 38.4776 13.3189C37.4725 10.8924 35.9993 8.68757 34.1421 6.8304C32.285 4.97323 30.0802 3.50004 27.6537 2.49494C25.2272 1.48985 22.6264 0.972534 20 0.972534ZM30.42 16.3925L18.42 28.3925C18.2341 28.58 18.0129 28.7288 17.7692 28.8303C17.5254 28.9319 17.264 28.9841 17 28.9841C16.736 28.9841 16.4746 28.9319 16.2309 28.8303C15.9871 28.7288 15.7659 28.58 15.58 28.3925L9.58001 22.3925C9.39353 22.2061 9.24561 21.9847 9.14469 21.741C9.04377 21.4974 8.99183 21.2363 8.99183 20.9725C8.99183 20.4399 9.2034 19.9291 9.58001 19.5525C9.95662 19.1759 10.4674 18.9644 11 18.9644C11.5326 18.9644 12.0434 19.1759 12.42 19.5525L17 24.1525L27.58 13.5525C27.9566 13.1759 28.4674 12.9644 29 12.9644C29.5326 12.9644 30.0434 13.1759 30.42 13.5525C30.7966 13.9291 31.0082 14.4399 31.0082 14.9725C31.0082 15.5051 30.7966 16.0159 30.42 16.3925Z"
                      fill="#25AB21"
                    />
                  </svg>

                  <h3 className="my-2" style={{ fontWeight: "bold" }}>
                    Confirmed
                  </h3>
                  <p style={{ color: COLORS.lightgrey }}>
                    You will receive a call from our insurance expert to help
                    you choose the suitable plan for you.
                  </p>
                </Grid>
                <Grid size={12}>
                  <h4 className="mb-2">Preferred time:</h4>
                  <p className="mb-2" style={{ color: COLORS.lightgrey }}>
                    {/* 12:00 - 14:00 PM, Thursday */}
                    {selectedTime} , {dayjs(selectedDate).format("dddd")}
                  </p>
                  <p style={{ color: COLORS.lightgrey }}>
                    {/* Nov 12, 2024 */}
                    {dayjs(selectedDate).format("MMM DD, YYYY")}
                  </p>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default ScheduleCallPopup;
