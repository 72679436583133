import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { Box, Button, Collapse, Link, Menu, MenuItem } from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Grid from "@mui/material/Grid2";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LoginContainer from "../../../../Container/MyAccountContainer/LoginContainer/LoginContainer";
import { COMMON_ROUTES } from "../../../../Router/Path/CommonRoutes";
import { MY_ACCOUNT_ROUTES } from "../../../../Router/Path/MyAccountRoutes";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { UserProfileSlice } from "../../../../Store/Slice_Reducer/Account/UserProfileSlice";
import { COLORS } from "../../../../SupportingFiles/colors";
import { FRONTEND_DOMAIN } from "../../../../URLCollection/Domain";
import BackLeft from "../../../InputFields/BackLeft";
import JDSButtons from "../../../InputFields/JDSButtons/JDSButtons";
import "./MNavbar.scss";
import { SESSION_CONSTANTS } from "../../../../Services/Constants/SessionConstants";
import { detectWebViewEnvironment } from "../../../../SupportingFiles/HelpingFunction";
import { SendEventToApp } from "../../../../SupportingFiles/SendEventToApp";

const MNavBar = ({ title }: { title?: any }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { Car, TW, Health, Term } = useAppSelector((state) => state);
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer =
    (anchor: "left", open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const [loginPopup, setLoginPopup] = useState(false);
  const { USER_DATA } = useAppSelector((state) => state.USER_STATE);
  const [timer, setTimer] = useState(30);
  useEffect(() => {
    let intervalId: any;

    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);

  const [openProductMenu, setOpenProductMenu] = useState(false);
  const [isRedirected, setIsRedirected] = useState<boolean>(true);
  const [productType, setProductType] = useState("");

  useEffect(() => {
    setIsRedirected(detectWebViewEnvironment());

    // Check if pathname contains any of the specific routes
    if (window.location.pathname.includes(COMMON_ROUTES.PRODUCT_CAR)) {
      setProductType("Car Insurance");
    } else if (window.location.pathname.includes(COMMON_ROUTES.PRODUCT_TW)) {
      setProductType("Two-Wheeler Insurance");
    } else if (
      window.location.pathname.includes(COMMON_ROUTES.PRODUCT_HEALTH)
    ) {
      setProductType("Health Insurance");
    } else if (window.location.pathname.includes(COMMON_ROUTES.PRODUCT_TERM)) {
      setProductType("Life Insurance");
    }
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(UserProfileSlice.actions.setAccountUserDataMobile(""));
    sessionStorage.removeItem(SESSION_CONSTANTS.USER_MOBILE_NO);
    navigate("/");
  };
  // Check if the current path is the root domain (i.e., home page)
  const isHomePage = window.location.pathname === "/";

  const webviewBackLeftClick = () => {
    // Create a URLSearchParams object from the query string
    const params = new URLSearchParams(location.search);

    // Check if fromInsurance is true
    const fromInsurance = params.get("fromInsurance") === "true";

    if (fromInsurance) {
      navigate(-1); // Go back if there's history
    } else if (window.history.state?.idx > 0) {
      navigate(-1); // Go back if there's history
    } else {
      SendEventToApp({ type: "close" }, (response: Record<string, any>) => {
        // Handle the response if needed
        console.log("Close event response:", response);
        // You can also show a toast or perform other actions here
      });
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: COLORS.lightMariGold20,
        height: "64px",
        position: "fixed",
        top: "0px",
        width: "100%",
        zIndex: "91",
        display: "flex",
        alignItems: "center",
        padding: "0px 12px",
      }}
    >
      <Grid container className="row" spacing={3} alignItems="center">
        <Grid size={8} display={"flex"} alignItems={"center"} gap={"12px"}>
          {isRedirected ? (
            <Box className="topbar">
              <Grid container className="row" alignItems="center">
                <Grid
                  size={12}
                  display={"flex"}
                  gap={"12px"}
                  alignItems={"center"}
                >
                  {isRedirected ? (
                    <>
                      <BackLeft onClick={webviewBackLeftClick} />
                      <h6> {productType ? productType : title}</h6>
                    </>
                  ) : (
                    <>
                      <Link
                        sx={{ display: "inline-block", height: "24px" }}
                        onClick={toggleDrawer("left", true)}
                      >
                        <MenuRoundedIcon style={{ color: "#000000" }} />
                      </Link>
                      <Link
                        sx={{ display: "inline-block", padding: "6px 0px" }}
                        onClick={() => {
                          if (window.location.pathname === COMMON_ROUTES.HOME) {
                            window.location.reload();
                          } else {
                            navigate(COMMON_ROUTES.HOME);
                          }
                        }}
                      >
                        <img
                          src="../images/jio_insurance_logo.svg"
                          alt="Jio Insurance Brokers"
                          height="16px"
                        />
                      </Link>
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
          ) : (
            <>
              <Link
                sx={{ display: "inline-block", height: "24px" }}
                onClick={toggleDrawer("left", true)}
              >
                <MenuRoundedIcon style={{ color: "#000000" }} />
              </Link>
              <Link
                sx={{ display: "inline-block", padding: "6px 0px" }}
                onClick={() => {
                  if (window.location.pathname === COMMON_ROUTES.HOME) {
                    window.location.reload();
                  } else {
                    navigate(COMMON_ROUTES.HOME);
                  }
                }}
              >
                <img
                  src="../images/jio_insurance_logo.svg"
                  alt="Jio Insurance Brokers"
                  height="16px"
                />
              </Link>
            </>
          )}
        </Grid>
        <Grid size={4} textAlign={"right"} className="loginBtns">
          {isHomePage ? (
            USER_DATA?.mobile?.value ? (
              <>
                <Button
                  className="userIcon"
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <img src="../images/user-icon.svg" alt="user" />
                </Button>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  slotProps={{
                    paper: {
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 4px 12px rgba(0,0,0,0.2))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      const encodedMobile = btoa(USER_DATA.mobile.value);
                      sessionStorage.setItem(
                        SESSION_CONSTANTS.USER_MOBILE_NO,
                        encodedMobile
                      );
                      window.location.href = `${FRONTEND_DOMAIN}${MY_ACCOUNT_ROUTES.DASHBOARD}${encodedMobile}`;
                    }}
                  >
                    Dashboard
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </>
            ) : (
              <JDSButtons
                onClick={() => setLoginPopup(true)}
                className="primaryBtns small"
                text="Login"
                variant="text"
                fullWidth={false}
              />
            )
          ) : null}
        </Grid>
      </Grid>
      <LoginContainer open={loginPopup} setOpen={setLoginPopup} />
      {isRedirected ? null : (
        <SwipeableDrawer
          anchor="left"
          open={state.left}
          onClose={toggleDrawer("left", false)}
          onOpen={toggleDrawer("left", true)}
        >
          <Box className="menuWrapper">
            <Box className="navSec pt-6">
              <img
                src="./images/jio_insurance_logo.svg"
                alt="Jio Insurance Brokers"
                height="16px"
              />
            </Box>
            <Box className="menu-section">
              <ul>
                <li>
                  <Link
                    className="nav-link"
                    onClick={() => navigate(COMMON_ROUTES.HOME)}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ justifyContent: "space-between" }}
                    className="nav-link"
                    onClick={() => setOpenProductMenu(!openProductMenu)}
                  >
                    Products <KeyboardArrowDownIcon sx={{ color: "#9B6817" }} />
                  </Link>
                  <Collapse in={openProductMenu} timeout="auto" unmountOnExit>
                    <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_CAR)}>
                      Car Insurance
                    </Link>
                    <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_TW)}>
                      Two-Wheeler Insurance
                    </Link>
                    <Link
                      onClick={() => navigate(COMMON_ROUTES.PRODUCT_HEALTH)}
                    >
                      Health Insurance
                    </Link>

                    <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_TERM)}>
                      Term Insurance
                    </Link>
                    <Link
                      onClick={() => navigate(COMMON_ROUTES.PRODUCT_NON_TERM)}
                    >
                      Investment Plans
                    </Link>
                  </Collapse>
                </li>
                <li>
                  <Link
                    className="nav-link"
                    onClick={() => navigate(COMMON_ROUTES.ABOUT_US)}
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    className="nav-link"
                    onClick={() => navigate(COMMON_ROUTES.CONTACT)}
                  >
                    Contact
                  </Link>
                </li>
                <li>
                  <Link
                    className="nav-link"
                    onClick={() => navigate(COMMON_ROUTES.CAREER)}
                  >
                    Career
                  </Link>
                </li>
                <li>
                  <Link
                    className="nav-link"
                    onClick={() => navigate(COMMON_ROUTES.BLOGS)}
                  >
                    Blogs
                  </Link>
                </li>
              </ul>
            </Box>
          </Box>
        </SwipeableDrawer>
      )}
    </Box>
  );
};

export default MNavBar;
